<template>
        <v-card>
            <v-row>
                <v-col align-self="center">
                    <div style="text-align: center;font-weight:bold ;">
                        About the Chef
                    </div>
                    <div class="container" style="text-align: left">
                        {{chef.profile.about}}
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col align-self="stretch">
                    <div style="text-align: center;font-weight:bold ;">
                        Location
                    </div>
                    <div class="container" align-content="center" align="center" v-if="chef.userDetails && chef.userDetails.city">
                         {{chef.userDetails.city}}
<!--                        + {{chefSelected.location[0].maxRange}} Km-->
                    </div>
                </v-col>
                <v-col align-self="center">
                    <div style="text-align: center;font-weight:bold ;">
                        Spoken Language
                    </div>
                    <div class="container" align-content="center" align="center">
                        <div class="container" style="display:inline;text-align: left"
                             v-for="language in chef.profile.languages" v-bind:key="language.name">
                            {{language.displayName}}
                        </div>
                    </div>

                </v-col>
            </v-row>
<!--            <v-row>-->
<!--                <v-col align-self="center">-->
<!--                    <div style="text-align: center;font-weight:bold ;">-->
<!--                        Type of cuisine-->
<!--                    </div>-->
<!--                    <div class="container" align-content="center" align="center">-->
<!--                        <v-chip class="" style="margin:0px 12px 0px 12px ;" v-for="type in chef.types"-->
<!--                                v-bind:key="type">-->
<!--                            {{type}}-->
<!--                        </v-chip>-->
<!--                    </div>-->
<!--                </v-col>-->
<!--            </v-row>-->
        </v-card>
</template>

<script>
    export default {
        props: {
            chef: Object
        },
    }
</script>