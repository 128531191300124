<template>
    <div class="menu" v-if="chef && serviceSelectedCd!==undefined">
        <v-dialog v-model="data.gallery" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-btn icon @click="data.gallery = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="gallery">
                    <div
                            v-for="(src, index) in  chef.photos"
                            :key="index"
                            class="pic"
                            @click="data.photoDetails=true;data.photoIndex=index;$log(data.photoIndex)"
                    >
                        <!--                                @click="() => showImg(index)">-->
                        <img :src="src.photoPath+src.photoFilename" class="gallery-pic">
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="data.photoDetails" fullscreen content-class="caroussel-dialog-fullscreen" hide-overlay>
            <v-btn icon dark @click="data.photoDetails = false" style="position: absolute;z-index: 1;">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-carousel v-if="chef"
                        show-arrows
                        hide-delimiters
                        class="caroussel-fullscreen"
                        v-model="data.photoIndex"
                        height="100%"
            >
                <v-carousel-item contain
                                 v-for="(photo,index) in chef.photos"
                                 :key="index"
                                 :src="photo.photoPath+photo.photoFilename"

                ></v-carousel-item>
            </v-carousel>
        </v-dialog>
        <v-carousel v-if="chef"
                    show-arrows
                    hide-delimiters
                    class="caroussel"
                    height="250px"
        >
            <v-carousel-item
                    v-for="(photo,index) in chef.photos"
                    :key="index"
                    :src="photo.photoPath+photo.photoFilename"
                    @click="data.gallery = true"
            ></v-carousel-item>
            <div class="chefSearchTop" style="width:100%" @click="data.gallery = true">
                <v-col>
                    <div class="row">
                        <div class="col-md-12 white--text" style="visibility: visible;">
                            <h2 class="" style="text-align: center;" v-if="chef">
                                Select your dishes from {{chef.profile.profileName}}´s menu
                            </h2>
                        </div>
                    </div>
                </v-col>
            </div>
            <v-row class="chefSearchBottom " align="end" justify="end" no-gutters style="padding: 0px">
                <v-container>
                    <v-btn outlined @click="data.gallery = true" class="btn-black-30">
                        Gallery
                    </v-btn>
                </v-container>
            </v-row>
        </v-carousel>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container fuild style="margin-top: -48px;">
            <v-card v-if="chef">
                <v-row style="margin-left: 12px;margin-right: 12px">
                    <v-col align="start" class="col-auto">
                        <v-avatar v-if="chef.profile.photoPath && chef.profile.photoFilename" size="124">
                            <img style="object-fit: cover;"
                                    :src="chef.profile.photoPath+chef.profile.photoFilename"
                                    :alt="chef.profile.profileName"
                            />
                        </v-avatar>
                    </v-col>
                    <v-col align-self="center" justify="start">
                        <div class="flex-row" style="white-space: nowrap;font-size: x-large;">
                            {{ chef.profile.profileName }}
                        </div>
                        <div class="flex-row" style="white-space: nowrap;font-size: large;"> {{ chef.location
                            }}
                        </div>
                    </v-col>
                    <v-col align="end" align-self="center" class="col-auto">
                        <star-rating read-only :increment="0.5" v-model="rating" :star-size="35" align="end"
                                     align-self="center" inline></star-rating>
                    </v-col>
                </v-row>
                <v-row style="margin-left: 12px;margin-right: 12px" align-content="center" align="center"
                       justify="center">
                    <a href="javascript:void(0)" @click="toggleChef">
                        {{data.aboutChef?"less":"more"}} about the chef
                    </a>
                </v-row>
            </v-card>

        </v-container>
        <v-container v-if="chef && data.aboutChef">
            <about-chef :chef="chef" ></about-chef>
        </v-container>
        <v-container>
            <v-row>
                <!-- Package-->
                <div v-if="packageSelectedCd!==undefined && serviceSelectedCd!==undefined" class="col-xs-12 col-md-8">
                <v-card v-show="serviceSelectedCd!==undefined">
                  <v-col align-self="center">
                    <v-row style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                      <v-col cols="8" align="start"><div style="text-align: left;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">{{serviceSelectedCd.serviceType.displayName}}: {{packageSelectedCd.name}} <br/> {{formatPackageMinMaxGuests(packageSelectedCd)}}</div></v-col>
                      <v-col cols="4" align="end">
                        ${{packageSelectedCd.price}} per person
                      </v-col>
                    </v-row>
<!--                    <div style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">{{serviceSelectedCd.serviceType.displayName}}: {{packageSelectedCd.name}} </div>-->
                    <div v-for="(packageCategory) in packageCategoriesSorted(packageSelectedCd)" :key="packageCategory.name" class="margin-top-default">
                      <!--                                <div v-if="category.items.length>0">-->
                      <div v-if="filteredDishesByCategoryForPackages(serviceSelectedCd,packageCategory).length>0">
                        <div class="" style="text-align: center;">
                          <v-row>
                            <v-col align="start" class="margin-bottom">
                              <div class="primary--text " style="display:inline;text-align: left;font-weight:bold ;">
                                <span>{{packageCategory.itemCategory.name}} </span><span class="caption restrictions">{{formatPackageCategorySelection(packageCategory)}}</span>
                              </div>
                            </v-col>
                            <v-col align="end">
<!--                              <v-btn v-if="$root.dishesSelected[packageCategory.itemCategory.name]!==undefined " @click="$root.dishesSelected[packageCategory.itemCategory.name]=undefined;" icon >-->
<!--                                <v-icon>mdi-delete</v-icon>-->
<!--                              </v-btn>-->
                            </v-col>
                          </v-row>
                        </div>
                        <div class="no-margin-padding margin-bottom" >
                          <v-row style="text-align: left;" v-for="(dish,indexDish) in filteredDishesByCategoryForPackages(serviceSelectedCd,packageCategory)"
                                 v-bind:key="indexDish" class="">
                            <v-col>
<!--                              @change="updatePriceDishesSelected(packageCategory.itemCategory,dish,$event, packageSelectedCd)"-->
                              <v-checkbox
                                  :disabled="checkAllInclusive(serviceSelectedCd,packageCategory )"
                                  class="no-margin-padding"
                                  :value="dish"
                                  :multiple="true"
                                  v-model="$root.dishesSelected[packageCategory.itemCategory.name]"
                                  hide-details="auto" :label="dish.description"
                                  @click="selectItem(serviceSelectedCd,packageCategory)"
                              >
                                <template v-slot:label sytle="color:black !important">
                                  <div>
                                    <v-row no-gutters style="align-self: center">
                                      <v-col class="black--text">
                                        {{dish.description}}
                                      </v-col>
                                    </v-row>
                                    <v-row no-gutters style="align-self: center">
                                      <v-col cols="auto" style="align-self: center">
                                        <v-chip v-for="tag in dish.styleTags"
                                                :key="tag.name"
                                                x-small
                                        >{{tag.displayName}}</v-chip>
                                      </v-col>
                                      <v-col cols="auto" style="align-self: center">
                                        <v-chip v-for="tag in dish.dietaryTags"
                                                :key="tag.name"
                                                x-small
                                        >{{tag.displayName}}</v-chip>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </template>
                              </v-checkbox>
                            </v-col>
                            <v-col class="col-auto" align-self="center">
                            </v-col>
                          </v-row>
                        </div>
                        <v-row style="text-align: left;" v-if="packageCategory.comment">
                          <v-col style="align-self: center;">
                            <div class=" comment">
                              {{packageCategory.comment}}
                            </div>
                          </v-col>
                        </v-row>
                        <div class="error--text">{{errors[packageCategory.itemCategory.name]}}</div>
                        <v-divider></v-divider>

                      </div>

                    </div>
                  </v-col>
                </v-card>
                  <div class="container" v-if="serviceSelectedCd.packages && serviceSelectedCd.packages.length>1" >
                    <div style="display: inline">See package : </div>
                    <div style="display: inline" v-for="(service,index) in chef.service" :key="index">
                      <div style="display: inline" v-for="(pack,index2) in service.packages" :key="index2">
                        <a v-if="chef && $root.serviceSelected!=null && $root.serviceSelected.name===service.serviceType.name && $root.packageSelected.id!==pack.id" class="container" @click="changePackage(pack)" >{{pack.name}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="container" v-if="chef.service.length>1" >
                    <div style="display: inline">Change type of service : </div>
                    <div style="display: inline" v-for="(service,index) in chef.service" :key="index">
                      <a v-if="chef && $root.serviceSelected!=null && $root.serviceSelected.name!==service.serviceType.name || $root.packageSelected!==undefined" @click="changeservice(service.serviceType)" class="container"> {{service.serviceType.displayName}}</a>
                    </div>
                  </div>
              </div>
                <!-- MENU A LA CARTE-->
                <div v-else class="col-xs-12 col-md-8">
                    <v-card v-show="serviceSelectedCd!==undefined">
                        <v-col align-self="center">
                            <div style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">{{serviceSelectedCd.serviceType.displayName}}</div>

<!--                            <div v-for="(dishType,indexDishType) in $store.state.dishType.filter(d => d!==undefined && d.name !== 'KID')" :key="indexDishType">-->
                                <div v-for="(category) in serviceSelectedCd.itemCategories" :key="category.name" class="margin-top-default">
<!--                                <div v-if="category.items.length>0">-->
                                  <div v-if="filteredDishesByCategory(serviceSelectedCd,category).length>0">
                                    <div class="" style="text-align: center;">
                                        <v-row>
                                          <v-col align="start" class="margin-bottom">
                                            <div class="primary--text "
                                                 style="display:inline;text-align: left;font-weight:bold ;">
                                              {{ category.name }}
                                            </div>
                                            <v-btn v-if="$root.dishesSelected[category.name]!==undefined "
                                                   @click="clearSelection(category)" icon>
                                              <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                          </v-col>

                                          <v-col v-if="category.categoryType.name=='KID'" align="end" class="" cols="2" style="align-self: center">
                                            $
                                            {{category.pricingType == undefined || category.pricingType.displayName != 'UNIT' ? "Per Kids" : category.pricingType.displayName}}
                                          </v-col>
                                          <v-col v-else align="end" class="" cols="2" style="align-self: center">
                                            $
                                            {{ category.pricingType != undefined && category.pricingType !== null ? category.pricingType.displayName : "" }}
                                          </v-col>
                                        </v-row>
                                    </div>
                                    <!--                                    <div v-if="category.pricingType.name==='UNIT'" class="no-margin-padding margin-bottom" >-->
                                    <!--                                      &lt;!&ndash;                                        <v-row style="text-align: left" v-for="(dish,indexDish) in filteredDishes(serviceSelectedCd,dishType)"&ndash;&gt;-->
                                    <!--                                      &lt;!&ndash;                                               v-bind:key="indexDish">&ndash;&gt;-->
                                    <!--                                      <v-row style="text-align: left;" v-for="(dish,indexDish) in filteredDishesByCategory(serviceSelectedCd,category)"-->
                                    <!--                                             v-bind:key="indexDish" class="">-->

                                    <!--                                          <v-col>-->
                                    <!--                                            <div class="" style="margin-right:8px;width:24px;height:24px;display:inline-block"></div>-->
                                    <!--                                            <div style="display:inline-block">-->
                                    <!--                                              <v-row no-gutters style="align-self: center">-->
                                    <!--                                                <v-col class="black&#45;&#45;text">-->
                                    <!--                                                  {{dish.description}}-->
                                    <!--                                                </v-col>-->
                                    <!--                                              </v-row>-->
                                    <!--                                              <v-row no-gutters style="align-self: center">-->
                                    <!--                                                <v-col cols="auto" style="align-self: center">-->
                                    <!--                                                  <v-chip v-for="tag in dish.styleTags"-->
                                    <!--                                                          :key="tag.name"-->
                                    <!--                                                          x-small-->
                                    <!--                                                  >{{tag.displayName}}</v-chip>-->
                                    <!--                                                </v-col>-->
                                    <!--                                                <v-col cols="auto" style="align-self: center">-->
                                    <!--                                                  <v-chip v-for="tag in dish.dietaryTags"-->
                                    <!--                                                          :key="tag.name"-->
                                    <!--                                                          x-small-->
                                    <!--                                                  >{{tag.displayName}}</v-chip>-->
                                    <!--                                                </v-col>-->
                                    <!--                                              </v-row>-->
                                    <!--                                            </div>-->
                                    <!--                                        </v-col>-->
                                    <!--                                        <v-col class="col-auto" align-self="center">-->
                                    <!--                                          <div class="padding-left-right-default padding-top-bottom-default" style="display:inline-block;width:80px">-->
                                    <!--                                            <v-text-field :id="'qty_'+indexDish"-->
                                    <!--                                                          style="display:inline-flex"-->
                                    <!--                                                          dense-->
                                    <!--                                                          label="qty"-->
                                    <!--                                                          type="number"-->
                                    <!--                                                          v-model.number="$root.nbGuests"-->
                                    <!--                                            ></v-text-field>-->
                                    <!--                                          </div>-->
                                    <!--                                          {{getPriceWithCommission(dish.price)}}-->
                                    <!--                                        </v-col>-->
                                    <!--                                      </v-row>-->
                                    <!--                                    </div>-->
                                    <v-radio-group v-model="$root.dishesSelected[category.name]"
                                                   class="no-margin-padding"
                                                   v-if="category.multiSelect!==true">
                                      <v-row style="text-align: left"
                                             v-for="(dish,indexDish) in filteredDishesByCategory(serviceSelectedCd,category)"
                                             v-bind:key="indexDish">
                                        <!--                                          <v-row style="text-align: left;" v-for="(dish,indexDish) in category.items"-->
                                        <!--                                                 v-bind:key="indexDish" class="margin-top-default">-->
                                        <v-col align-self="center">
<!--                                          :disabled="category.pricingType.name==='UNIT'"-->
                                          <v-radio

                                            class="no-margin-padding"
                                            :value="dish"
                                            hide-details="auto" :label="dish.description"
                                            @change="changeSelected(category,dish)"
                                            style="border: 0px"
                                          >
                                            <template v-slot:label sytle="color:black !important">
                                              <div>
                                                <v-row no-gutters style="align-self: center">
                                                  <v-col class="black--text">
                                                    <span>{{dish.description}}</span><span class="caption restrictions">{{formatItemRestrictions(dish)}}</span>
                                                  </v-col>
                                                </v-row>
                                                <v-row no-gutters style="align-self: center">
                                                                <v-col cols="auto" style="align-self: center">
                                                                    <v-chip v-for="tag in dish.styleTags"
                                                                            :key="tag.name"
                                                                            x-small
                                                                    >{{tag.displayName}}</v-chip>
                                                                </v-col>
                                                                <v-col cols="auto" style="align-self: center">
                                                                    <v-chip v-for="tag in dish.dietaryTags"
                                                                            :key="tag.name"
                                                                            x-small
                                                                    >{{tag.displayName}}</v-chip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </template>
                                                </v-radio>
                                            </v-col>
                                            <v-col class="col-auto" align-self="center">
                                              <div v-if="category.pricingType.name==='UNIT'"
                                                   class="padding-left-right-default "
                                                   style="display:inline-block;width:80px">
                                                <v-text-field :id="'qty_'+indexDish"
                                                              style="display:inline-flex"
                                                              dense
                                                              label="qty"
                                                              type="number"
                                                              v-model.number="dish.qty"
                                                              :hide-details="true"
                                                              :min="0"
                                                              :max="dish.max"
                                                              @input="qtyChange(dish,category, $event)"
                                                ></v-text-field>

                                              </div>
                                                {{getPriceWithCommission(dish.price)}}
                                            </v-col>
                                        </v-row>
                                    </v-radio-group>
                                    <div v-else class="no-margin-padding margin-bottom">
                                      <!--                                        <v-row style="text-align: left" v-for="(dish,indexDish) in filteredDishes(serviceSelectedCd,dishType)"-->
                                      <!--                                               v-bind:key="indexDish">-->
                                      <v-row style="text-align: left;"
                                             v-for="(dish,indexDish) in filteredDishesByCategory(serviceSelectedCd,category)"
                                             v-bind:key="indexDish" class="">
                                        <v-col>
<!--                                          @change="updatePriceDishesSelected(category,dish,$event)"-->
<!--                                          :disabled="category.pricingType.name==='UNIT'"-->
                                          <v-checkbox
                                              class="no-margin-padding"
                                              :value="dish"
                                              hide-details="auto"
                                              :label="dish.description"
                                              :multiple="true"
                                              v-model="$root.dishesSelected[category.name]"
                                              @change="changeSelected(category,dish,$event)"
                                          >
                                            <template v-slot:label sytle="color:black !important">
                                              <div>
                                                <v-row no-gutters style="align-self: center">
                                                  <v-col class="black--text">
                                                    <span>{{dish.description}}</span><span class="caption restrictions">{{formatItemRestrictions(dish)}}</span>
                                                  </v-col>
                                                </v-row>
                                                <v-row no-gutters style="align-self: center">
                                                  <v-col cols="auto" style="align-self: center">
                                                                    <v-chip v-for="tag in dish.styleTags"
                                                                            :key="tag.name"
                                                                            x-small
                                                                    >{{tag.displayName}}</v-chip>
                                                                </v-col>
                                                                <v-col cols="auto" style="align-self: center">
                                                                    <v-chip v-for="tag in dish.dietaryTags"
                                                                            :key="tag.name"
                                                                            x-small
                                                                    >{{tag.displayName}}</v-chip>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </template>
                                                </v-checkbox>
                                            </v-col>
                                        <v-col class="col-auto" align-self="center">
                                          <div v-if="category.pricingType.name==='UNIT'"
                                               class="padding-left-right-default "
                                               style="display:inline-block;width:80px">
                                            <v-text-field :id="'qty_'+indexDish"
                                                          style="display:inline-flex"
                                                          dense
                                                          label="qty"
                                                          type="number"
                                                          v-model.number="dish.qty"
                                                          :hide-details="true"
                                                          :min="0"
                                                          :max="dish.max"
                                                          @input="qtyChange(dish,category)"
                                            ></v-text-field>
                                          </div>
                                          {{ getPriceWithCommission(dish.price) }}
                                        </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider></v-divider>

                                </div>

                            </div>

                        </v-col>
                    </v-card>

                  <div class="container" v-if="serviceSelectedCd.packages && serviceSelectedCd.packages.length>0" >
                    <div style="display: inline">See package : </div>
                    <div style="display: inline" v-for="(service,index) in chef.service" :key="index">
                      <div style="display: inline" v-for="(pack,index2) in service.packages" :key="index2">
                        <a v-if="chef && $root.serviceSelected!=null && $root.serviceSelected.name===service.serviceType.name" class="container" @click="changePackage(pack)" >{{pack.name}}</a>
                      </div>
                    </div>
                  </div>
                    <div class="container" v-if="chef.service.length>1" >
                        <div style="display: inline">Change type of service : </div>
                        <div style="display: inline" v-for="(service,index) in chef.service" :key="index">
                            <a v-if="chef && $root.serviceSelected!=null && $root.serviceSelected.name!==service.serviceType.name" @click="changeservice(service.serviceType)" class="container"> {{service.serviceType.displayName}}</a>
                        </div>
                    </div>
                </div>

                <!-- Booking-->
                <div class="col-xs-12 col-md-4">
                    <v-card dark style="margin-bottom:12px; ">
                        <!-- Booking Param-->
                        <v-container>
                            <h2>Booking Details</h2>
                        </v-container>
                        <v-divider></v-divider>
                        <div class="background-black-light">
                            <v-col>
                                <v-autocomplete
                                    id="address"
                                    :no-filter="true"
                                    dark
                                    clearable
                                    v-model="$root.formData.location"
                                    :items="$root.formData.locationItems"
                                    :loading="isLoading"
                                    :search-input.sync="lookupAddress"
                                    color="white"
                                    item-text="place_name"
                                    label="Location"
                                    placeholder="Start typing your address"
                                    prepend-icon="mdi-map-marker"
                                    append-icon=""
                                    return-object
                                    @change="getLocation"
                                    auto-select-first
                                    hide-no-data
                                    dense
                                    :rules="[v => !!v || 'Location is required', v => this.checkRange()[0] || 'Chef out of range by '+this.checkRange()[1]+' Km']"
                                ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-menu
                                  v-model="datepicker"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <!--                                            @input="search"-->
                                  <v-text-field
                                      id="date"
                                      dense
                                      v-model="$root.formData.date"
                                      clearable
                                      dark
                                      label="Date"
                                      prepend-icon="mdi-calendar-range"
                                      readonly
                                      v-on="on"
                                      v-bind="attrs"
                                      @click:clear="$root.formData.date=undefined"
                                      :rules="[v => !!v || 'Date is required']"
                                  ></v-text-field>
                                </template>
                                <!--                                        @change="search"-->
<!--                                :allowed-dates="checkAvailableDate"-->
                                <v-date-picker
                                    color="primary"
                                    header-color="primary"
                                    :min="tomorrow"
                                    :allowed-dates="checkAvailableDate"
                                    v-model="$root.formData.date"
                                    @input="datepicker = false"
                                    :events="functionEvents"
                                >
                                  <template v-slot:default>
                                    <!-- legend-->
                                  </template>
                                </v-date-picker>

                              </v-menu>
                            </v-col>
                            <v-col>
                                <v-select
                                    id="time"
                                        dense
                                        dark
                                        clearable
                                        prepend-icon="mdi-clock"
                                        :items="availableTimes"
                                        label="Time"
                                        v-model="$root.formData.time"
                                        :rules="[v => !!v || 'Time is required']"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-text-field id="guests"
                                    dark
                                              dense
                                              prepend-icon="mdi-account-multiple"
                                              label="Guests"
                                              type="number"
                                              :min="serviceSelectedCd.minGuests!==undefined?serviceSelectedCd.minGuests:2"
                                              :max="serviceSelectedCd.maxGuests!==undefined?serviceSelectedCd.maxGuests:20"
                                              v-model.number="$root.nbGuests"
                                              :rules="[v => !!v || 'Number of guests is required', v => (serviceSelectedCd.minGuests===undefined || v>=serviceSelectedCd.minGuests) || 'Minimum required is '+serviceSelectedCd.minGuests]"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-textarea
                                  id="comment"
                                  prepend-icon="mdi-comment"
                                  dark
                                  dense
                                  v-model="$root.comment"
                                  label="Comment"
                                  hint="Tell the chef any allergy requirement or any additional information"
                                  placeholder="Tell the chef any allergy requirement or any additional information"
                              >
                              </v-textarea>
                            </v-col>
                        </div>
                    </v-card>
                    <!-- Cart -->
                    <v-card dark>
                        <v-container>
                            <h2>Cart</h2>
                        </v-container>
                        <div class="background-black-light">
<!--                          PACKAGE ------------------------------>
                          <div v-if="packageSelectedCd" >
                            <div class="row" style="width:100%;margin: 0px">
                              <v-col>{{packageSelectedCd.name}} x {{$root.nbGuests}}</v-col>
                              <v-col class="col-auto" align-self="end" v-if="packageSelectedCd.price !==undefined && $root.nbGuests!=undefined">
                                {{roundToXDigits((packageSelectedCd.price) * $root.nbGuests,2)}}
                              </v-col>
                            </div>
                            <div v-for="(packageCategory) in packageCategoriesSorted(packageSelectedCd)" :key="packageCategory.name">
                                <div class="row" style="margin: 0px" >
                                  <v-col>{{packageCategory.itemCategory.name}}</v-col>
                                  <v-col class="col-auto" align-self="end">
                                  </v-col>
                                </div>
                                <div class="row" style="font-size: 14px;width:100%;margin: 0px" v-for="(dish,index) in $root.dishesSelected[packageCategory.itemCategory.name]" :key="index">
                                  <v-col style="text-indent: 10px;">{{dish.description}} </v-col>
                                  <v-col class="col-auto" align-self="end">
                                  </v-col>
                                </div>
                            </div>
                          </div>
                          <div v-else>
<!--                            A LA CARTE ---------------------------->
                            <div v-for="(category) in serviceSelectedCd.itemCategories" :key="category.name" >
<!--                              <div v-if="(category.multiSelect===true || category.pricingType.name=='UNIT') && $root.dishesSelected[category.name]!==undefined && $root.dishesSelected[category.name].length!==undefined ">-->
                              <div v-if=" $root.dishesSelected[category.name]!==undefined && totalCategory(category)>0">
                                <div class="row" style="width:100%;margin: 0px" >
                                  <v-col>{{categoryTitle(category)}}</v-col>
                                  <v-col class="col-auto" align-self="end">
                                    {{formatPrice(totalCategory(category))}}
                                  </v-col>
                                </div>
                                  <div v-if="category.multiSelect===true">
                                    <div class="row" style="font-size: 14px;width:100%;margin: 0px" v-for="(dish,index) in $root.dishesSelected[category.name]" :key="index">
                                      <v-col style="text-indent: 10px;">{{itemTitle(category,dish)}}</v-col>
                                      <v-col class="col-auto" align-self="end">
                                        {{formatPrice(totalItem(category,dish))}}
                                      </v-col>
                                    </div>
                                  </div>
                                  <div v-if="category.multiSelect!==true">
                                    <div class="row" style="font-size: 14px;width:100%;margin: 0px">
                                      <v-col style="text-indent: 10px;">{{itemTitle(category,$root.dishesSelected[category.name])}} </v-col>
                                      <v-col class="col-auto" align-self="end">
                                        {{formatPrice(totalItem(category,$root.dishesSelected[category.name]))}}
                                      </v-col>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
<!--                                <div v-for="(dishType,indexDishType) in $store.state.dishType" :key="indexDishType">-->

                            <v-divider v-if="minChef"></v-divider>
                            <div  class="row" style="margin: 0px" v-if="minChef">
                                <v-col>Minimum order remaining</v-col>
                                <v-col class="col-auto" align-self="end">
                                    {{minChef}}
                                </v-col>
                            </div>
                            <div v-if="minChef" class="row" style="margin: 0px" >
                                <v-col align="center" class="caption" >
                                    <div>The chef requires a minimum order amount of {{formatPrice(getPriceWithCommission(serviceSelectedCd.minPrice))}}</div>
                                </v-col>
                            </div>
                            <v-divider  v-if="waiterPrice"></v-divider>
                            <div  class="row" style="margin: 0px" v-if="waiterPrice">
                                <v-col>Waiter</v-col>
                                <v-col class="col-auto" align-self="end">
                                    {{waiterPrice}}
                                </v-col>
                            </div>
                            <div v-if="waiterPrice" class="row" style="margin: 0px" >
                                <v-col align="center" class="caption" >
                                    <div>The chef requires a wait person for this order</div>
                                </v-col>
                            </div>
                            <v-divider v-if="errorDishes"></v-divider>
                            <div v-if="errorDishes" class="row" style="margin: 0px" >
                                <v-col align="center" class="error--text" >
                                    <div>Select a Starter or a Desert</div>
                                </v-col>
                            </div>
                        </div>
                        <v-divider></v-divider>
                        <div  class="row" style="margin: 0px;font-size: 18px">
                            <v-col>Total</v-col>
                            <v-col class="col-auto" align-self="end" v-if="total()!==undefined">
                                {{formatPrice(total())}}
                            </v-col>
                        </div>
                      <v-divider></v-divider>
                      <div  class="row" style="margin: 0px">
                        <v-col align="center">
                          <v-btn outlined color="primary" class="margin-default" :disabled="!checkErrors()" id="checkout" @click="checkout()">Check Out</v-btn>
<!--                          <div class="error&#45;&#45;text " v-if="!isDateAvailable">The chef is not marked as available for the date selected, but please Request a Date to the chef</div>-->
<!--                          <v-btn outlined color="primary" class="margin-default" v-if="!isDateAvailable" :disabled="!checkErrors()" id="checkout" @click="checkout(true)">Request a date</v-btn>-->
                          <div class="error--text " v-if="!checkErrors()">some errors are present</div>
                        </v-col>
                      </div>
                    </v-card>
                </div>
            </v-row>
        </v-container>
      </v-form>
    </div>
</template>

<script>

import StarRating from 'vue-star-rating'
import AboutChef from '../components/AboutChef';
import Vue from "vue";
Vue.component('star-rating', StarRating);
Vue.component('about-chef', AboutChef);

    // import {mapState} from "vuex";
    import {mapGetters} from 'vuex';
    // import chefs from "../chefs" //contain the fake db of chefs

    function initData(){
      let tomorrowDate=new Date();
      tomorrowDate.setDate(tomorrowDate.getDate()+2);
        return {
            packageSelectedCd:undefined,
            rating:5,
            valid:true,
            tomorrow:tomorrowDate.toISOString() ,
            isLoading:false,
            resultLocations:undefined,
            lookupAddress:undefined,
            outOfRange:false,
            // total:0,
            waiterPrice:undefined,
            minChef:undefined,
            errors:[],
            errorFound:false,
            data: {
                comment:undefined,
                appetizer: null,
                starter: null,
                main: null,
                desert: null,
                kid: null,
                canapes: null,
                grazing: null,
                loaded: false,
                aboutChef: false,
                guests:undefined,
                gallery: false,
                photoDetails: false,
                photoIndex: 0,
                datepicker:false
            }
        };
    }

    import LoadScript from 'vue-plugin-load-script'
    Vue.use(LoadScript)
    import { StripePlugin } from '@vue-stripe/vue-stripe';
    let pk= process.env.VUE_APP_STRIPE_API_KEY;

    const options = {
      //pk: "pk_test_51J8OdNAGb7MTLpmkowUVyVsfWEUSx6R98IGrcoqG6QnKOuPGBozH0RECZGhDess3WLvOIkJrD6NaIrzpDNEeOf0Q00vuPGeSiG",
      pk: pk,
      // stripeAccount: process.env.STRIPE_ACCOUNT,
      // apiVersion: process.env.API_VERSION,
      // locale: process.env.LOCALE,
    };

    new Promise((resolve) => {
      let script = document.createElement('script')
      script.onload = () => {
        resolve();
      }
      // script.async = true;
      script.defer = true;
      script.src = 'https://js.stripe.com/v3';
      document.head.appendChild(script);
    }).then(()=>{
      Vue.use(StripePlugin, options);
    })

    export default {
        name: "Menu",
        data() {
            return initData();
        },
      beforeCreate() {
        if (this.$root.serviceSelected===null || this.$root.serviceSelected===undefined) {
          this.$router.push("/");
          console.log("push /");
        }

      },
      mounted() {
        this.init();
      },
      computed: {
            ...mapGetters([
                'findChef',
                'isLoaded'
            ]),
            chefId() {
                return this.$route.params.id;
            },
            chef() {
                console.log(this.$root.chefSelected)
                return this.$root.chefSelected;
            },
            itineraryPath() {
                return `${this.chefId}`;
            },
            errorDishes(){
                if (this.$root.serviceSelected=="classic"){
                    if (this.data.starter==null && this.data.desert==null){
                        return true;

                    }else {return false;}
                }else{
                    return false;
                }
            },
            serviceSelectedCd(){
              if (this.$root.serviceSelected!==null && this.$root.serviceSelected!==undefined){
                return this.chef.service.find(st => st.serviceType.name===this.$root.serviceSelected.name)
              }else{
                return undefined
              }
            },
          availableDates(){
            let availableDates={};
            if (this.chef!==undefined){
                if (this.chef.events!=undefined){
                  this.chef.events.forEach(e => {
                    if (e.status.name==="AVAILABLE"){
                      availableDates[e.date]=true;
                    }
                  })
                }
            }
            return Object.keys(availableDates);
          },
          availableTimes(){
            let times=[];

            if (!this.isDateAvailable){
              times.push.apply(times,
                  this.$root.times.lunchTime()
              );
              times.push.apply(times,
                  this.$root.times.dinnerTime()
              );
            }else{
              if (this.isLunchAvailable){
                times.push.apply(times,
                    this.$root.times.lunchTime()
                );
              }
              if (this.isDinnerAvailable){
                times.push.apply(times,
                    this.$root.times.dinnerTime()
                );
              }
            }
            return times;
          },
          isLunchAvailable(){
            let available=false;
            let dateSelected=this.$root.formData.date;
            if (this.chef!==undefined ){
                if (this.chef.events!=undefined){
                  this.chef.events.forEach(e => {
                    if (e.status.name==="AVAILABLE" && e.eventTime.name==="LUNCH"){
                      if (dateSelected!==undefined && e.date===dateSelected){
                        available = true;
                      } else if (dateSelected === undefined || dateSelected == null) {
                        available = true;
                      }
                    }
                  })
                }
            }
            return available;
          },
          isDinnerAvailable(){
            let dateSelected=this.$root.formData.date;
            let available=false;

            if (this.chef!==undefined) {
                if (this.chef.events!=undefined){
                  this.chef.events.forEach(e => {
                    if (e.status.name === "AVAILABLE" && e.eventTime.name === "DINNER") {
                      if (dateSelected !== undefined && e.date === dateSelected) {
                        available = true;
                      } else if (dateSelected === undefined || dateSelected == null) {
                        available = true;
                      }
                    }
                  })
                }
            }
            return available;
          },
        isDateAvailable(){
          let dateSelected=this.$root.formData.date;
          let eventFound= false;

          if (this.chef!==undefined && this.chef.events!=undefined){
            this.chef.events.forEach(e => {
                if (dateSelected !== undefined && e.date === dateSelected) {
                  eventFound = true;
                }
              })
            }
          return eventFound;
        },
        },
        methods: {
          clearSelection(category){
            if (category.multiSelect) {
              console.log(this.$root.dishesSelected[category.name]);
              if (this.$root.dishesSelected[category.name]){
                for (var i = 0; i < this.$root.dishesSelected[category.name].length; i++) {
                  console.log(this.$root.dishesSelected[category.name][i].name);
                  delete this.$root.dishesSelected[category.name][i].qty;
                }
              }
            }else{
              delete this.$root.dishesSelected[category.name].qty ;
            }

            delete this.$root.dishesSelected[category.name]
            this.$forceUpdate();

          },
          changeSelected(category,dish){
            if (category.pricingType.name==="UNIT" && category.multiSelect===true && this.$root.dishesSelected[category.name]){
              if (this.$root.dishesSelected[category.name].filter(i => i.description ===dish.description).length==1){
                if (dish.min && dish.min > 0 ){
                  dish.qty=dish.min;
                }else{
                  dish.qty=1;
                }
              }else{
                delete dish.qty;
              }
            }
            if (category.pricingType.name==="UNIT" && category.multiSelect!==true){
              console.log("selected "+dish.description );
              if (this.$root.dishesSelected[category.name]){
                console.log("unselected: "+this.$root.dishesSelected[category.name].description)
                delete this.$root.dishesSelected[category.name].qty;
              }
              if (dish.min && dish.min > 0 ){
                dish.qty=dish.min;
              }else{
                dish.qty=1;
              }
            }
            if (category.multiSelect === true && this.$root.dishesSelected[category.name].length == 0) {
              delete this.$root.dishesSelected[category.name];
            }
            dish.previousQty=dish.qty;
          },
          qtyChange(dish, category, event) {
            console.log(event);

            if (!dish.previousQty && dish.qty > 0 || dish.previousQty < dish.qty) {
              // increase
              if (dish.min && dish.qty < dish.min){
                dish.qty = dish.min;
              }
            }else{
              // decrease
              if (dish.min && dish.qty < dish.min){
                dish.qty = 0;
              }
            }
            if (category.multiSelect){

              if (dish.qty <= 0 ) {
                try {
                  const indexToRemove = this.$root.dishesSelected[category.name].findIndex(obj => obj.id === dish.id);
                  if (indexToRemove !== -1) {
                    this.$root.dishesSelected[category.name].splice(indexToRemove, 1);
                  }
                  if (this.$root.dishesSelected[category.name].length===0) {
                    this.$delete(this.$root.dishesSelected, category.name);
                    // delete this.$root.dishesSelected[category.name];
                  }
                }catch (e) {
                  //do nothing
                }

              } else {
                if (dish.min && dish.qty < dish.min ) {
                  dish.qty = dish.min;
                }
                if (!this.$root.dishesSelected[category.name]){
                  this.$set(this.$root.dishesSelected, category.name, []);
                }
                if (!this.$root.dishesSelected[category.name] || this.$root.dishesSelected[category.name].filter(d => d.id === dish.id).length == 0) {
                  console.log("add the item");
                  this.$set(this.$root.dishesSelected[category.name],this.$root.dishesSelected[category.name].length,dish)
                }
              }
            }else{
              if (category.pricingType.name==="UNIT" && dish.qty>0 && ((this.$root.dishesSelected[category.name] && this.$root.dishesSelected[category.name].id!==dish.id) || !this.$root.dishesSelected[category.name])){

                if (this.$root.dishesSelected[category.name]){
                  this.$root.dishesSelected[category.name].qty=0;
                }
                if ( dish.min && dish.qty<dish.min){
                  dish.qty=dish.min;
                }
                this.$set(this.$root.dishesSelected,category.name,dish);
              }else if (category.pricingType.name==="UNIT" && dish.qty<=0 && (this.$root.dishesSelected[category.name] && this.$root.dishesSelected[category.name].id===dish.id)){
                // this.$set(this.$root.dishesSelected,category.name,undefined);
                this.$delete(this.$root.dishesSelected, category.name);
              }
            }
            dish.previousQty=dish.qty;
            this.$forceUpdate();
            // this.updatePriceDishesSelected(category,dish);
          },
          checkout(requestDate) {
            this.checkAllItems(this.serviceSelectedCd,this.packageSelectedCd);
            console.log(this.errors);

            if (this.validate() != true || Object.keys(this.errors).length > 0 ) {
              return
            }

            let action="CHECKOUT";
            let path="/checkout";
            if (requestDate){
              action="REQUEST_DATE";
              this.$root.requestDate=requestDate;
            }
            console.log(this.$root.loggedin)
            if (!this.$root.loggedin) {
              this.$root.actionAfterLogin = action;
              this.$root.showSignup=true;
            }else{
              this.$router.push({path: path});
            }
            // save the booking locally cart
            // check if logged in
            // if not show registration
            //
          },
          validate () {
            this.checkAllItems(this.serviceSelectedCd,this.packageSelectedCd);
            if (this.$refs.form!==undefined){
              return this.$refs.form.validate();
            }
          },
          init() {
            if ((this.$root.formData.guests==="" || this.$root.formData.guests===undefined) && this.serviceSelectedCd!==undefined && this.serviceSelectedCd.minGuests!==undefined){
              this.$root.nbGuests=this.serviceSelectedCd.minGuests;
            }else if (this.$root.formData.guests !== undefined){
              this.$root.nbGuests=this.$root.formData.guests;
            }
            this.packageSelectedCd=this.$root.packageSelected;
          },
          isLunchAvailableDate(date){
            let available=false;
            let dateSelected=date;
            if (this.chef!==undefined ){
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name==="AVAILABLE" && e.eventTime.name==="LUNCH"){
                    if (dateSelected!==undefined && e.date===dateSelected){
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
            }
            return available;
          },
          isDinnerAvailableDate(date){
            let dateSelected=date;
            let available=false;

            if (this.chef!==undefined) {
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name === "AVAILABLE" && e.eventTime.name === "DINNER") {
                    if (dateSelected !== undefined && e.date === dateSelected) {
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
            }
            return available;
          },
          functionEvents (date) {
            let classes=[];

            if (date < this.tomorrow.substring(0,10) || !this.isLunchAvailableDate(date) && !this.isDinnerAvailableDate(date)){
              return;
            }
            if (this.isLunchAvailableDate(date)){
              classes.push("available");
            }else{
              classes.push("unavailable");
            }
            if (this.isDinnerAvailableDate(date)){
              classes.push("available");
            }else{
              classes.push("unavailable");
            }
            return classes;
          },
          checkAvailableDate(date){
            if (this.availableDates!==undefined && this.availableDates.length>0){
              return this.availableDates.includes(date);
            }  else{
              return true;
            }
          },
          getLocation(val){
            console.log(val);
            if (this.$root.formData.location!==undefined){
              this.$root.formData.geolocation=val.center;
              // this.$store.dispatch('getGeolocationFullAddress',this.$root.formData.location).then(response => {
              //     this.geolocation=response;
              //     console.log(this.geolocation);
              // });


            }else{
              this.$root.formData.geolocation=undefined;
            }
            // this.search();

            return this.$root.formData.geolocation;
          },
          checkRule(){
            return false;
          },
          checkRange(){
            let result=[false,0];
            if (this.chef.locations!==undefined && this.$root.formData.geolocation){
              for (let i = 0; i < this.chef.locations.length; i++) {
                let location=this.chef.locations[i].location.coordinates;
                let distance = this.getDistanceFromLatLonInKm(location[1],location[0],this.$root.formData.geolocation[1],this.$root.formData.geolocation[0]);
                if (!result[0] && distance<=this.chef.locations[i].maxRange){
                  result=[true,0];
                  break;
                }else{
                  result=[false,(Math.round((distance-this.chef.locations[i].maxRange)*10)/10)];
                }
              }
            }
            return result;
          },
          checkAllItems(serviceSelectedCd,packageSelectedCd){
            if (packageSelectedCd!==undefined){
              for (const packageCategoryIndex in packageSelectedCd.packageCategories) {
                let packageCategory=packageSelectedCd.packageCategories[packageCategoryIndex];
                this.selectItem(serviceSelectedCd,packageCategory);
              }
            }
          },
          selectItem(serviceSelectedCd,packageCategory){
            delete this.errors[packageCategory.itemCategory.name];
            if (this.$root.dishesSelected === undefined || this.$root.dishesSelected[packageCategory.itemCategory.name] === undefined) {
              this.$root.dishesSelected[packageCategory.itemCategory.name]=[];
            }
            // let numberSelected = this.filteredDishesByCategoryForPackages(serviceSelectedCd,category).filter(i => i.selected ===true ).length;
            let numberSelected = this.$root.dishesSelected[packageCategory.itemCategory.name].length;
            //   let numberSelected= selected.count();
              if ((packageCategory.minItem !==undefined || packageCategory.selectionType.name==="SINGLE") && numberSelected < packageCategory.minItem ){
                this.errors[packageCategory.itemCategory.name] = "please select " + packageCategory.minItem + " minimum";
              }
              if (packageCategory.maxItem !==undefined && numberSelected > packageCategory.maxItem){
                this.errors[packageCategory.itemCategory.name] = "please select " + packageCategory.maxItem + " maximum";
              }
            if (packageCategory.selectionType.name==="SINGLE" && numberSelected != 1 ) {
              this.errors[packageCategory.itemCategory.name] = "please select only 1";
            }
            this.errorFound= Object.keys(this.errors).length>0;
          },
          checkErrors(){
            this.errorFound = Object.keys(this.errors).length>0;
            return this.valid && !this.errorFound;
          },
          checkAllInclusive(serviceSelectedCd,packageCategory){
            // if (this.$root.dishesSelected === undefined || this.$root.dishesSelected[packageCategory.itemCategory.name] === undefined) {
            //   this.$root.dishesSelected[packageCategory.itemCategory.name]=[];
            // }
            if (packageCategory.selectionType.name==="ALL_INCLUSIVE"){
              // this.filteredDishesByCategoryForPackages(serviceSelectedCd,category).forEach( i => i.selected=true);
              let selected = this.filteredDishesByCategoryForPackages(serviceSelectedCd,packageCategory)
              this.$root.dishesSelected[packageCategory.itemCategory.name] = selected;
              return true
            }else{
              return false;
            }
          },

            filteredDishes(service, dishType){
                if (service !== undefined && dishType!==undefined && service.dishes!==undefined){
                    return service.dishes.filter(d => d.dishType!==undefined && d.dishType.name === dishType.name)
                }else{
                    return [];
                }
            },
          filteredDishesByCategory(service, category){
            if (service !== undefined && category!==undefined && service.dishes!==undefined){
              return service.dishes.filter(d => d.itemCategory!==undefined && d.itemCategory.name === category.name)
            }else{
              return [];
            }
          },
          filteredDishesByCategoryForPackages(service, packageCategory) {
            if (service !== undefined && packageCategory !== undefined && service.dishes!=undefined) {
              if (packageCategory.limitedItems != undefined && packageCategory.limitedItems.length > 0){
                return service.dishes.filter(d => d.itemCategory !== undefined && d.itemCategory.name === packageCategory.itemCategory.name && packageCategory.limitedItems.find(i => i.id===d.id))
              }else{
                return service.dishes.filter(d => d.itemCategory !== undefined && d.itemCategory.name === packageCategory.itemCategory.name )
              }
            }
          },
            filteredDishesKid(service){
                if (service !==undefined && service.dishes!==undefined){
                    return service.dishes.filter(d => d.dishType!==undefined && d.dishType.name === 'KID')
                }
                return;
            },
            changeservice(serviceType){
                this.$root.serviceSelected=serviceType;
                this.$root.packageSelected=undefined;
                //reset data
                this.$root.dishesSelected=[];
                this.init();
                this.data=initData().data;
                this.setMain();
            },
            changePackage(pack){
            console.log("set package", pack);
              this.$root.packageSelected=pack;
              // this.$forceUpdate();
              //reset data
              this.$root.dishesSelected=[];
              this.init();
              this.data=initData().data;
            },
            toggleChef() {
                this.data.aboutChef = !this.data.aboutChef;
            },
            setMain() {
                if (this.chef && this.$root.serviceSelected=="classic") {
                    this.data.main = this.chef.main[0];
                    console.log('setMain');
                }
            },
            test(data) {
                console.log(this.$data);
                console.log(data);
            },
        },
        watch: {
          isLoaded: function (val) {
              if (val) {
                  this.data.main = this.chef.main[0];
              } else {
                  console.log("not loaded yet");
              }
              console.log("chef:", val);
          },
          lookupAddress (val) {
            if (val===undefined || val===null || val.length<4) {
              return
            }
            // Items have already been loaded
            // if (this.items !==undefined && this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            let that=this;

            // using mapbox
            this.$store.dispatch('lookupAddressMapbox',val).
            then(response => {
              that.$root.formData.locationItems=response; })
                .finally(() => (this.isLoading = false));
          },
        }
        // }
    };
</script>
